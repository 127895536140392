import {
  AlertData,
  TSort,
  TPagination,
  TFilter,
  TAlertType,
  TtrendTotalAlerts,
  TtrendTop5Alert,
  TtrendLocationWise,
  TAlertInsights,
  TPastAlertData,
  User,
  TDashboardList,
  TMapMarkers,
  TDropdownFilters,
  TMapViewFilters,
  TSearchOptions,
  TSearchFilter,
  TAlertPagination,
  TModule,
  TSubModule,
} from "./models";

export interface State {
  user: User;
  alerts: {
    smart: {
      [alertId: string]: AlertData;
    };
    bms: {
      [alertId: string]: AlertData;
    };
    mc: {
      [alertId: string]: AlertData;
    };
    charger: {
      [alertId: string]: AlertData;
    };
    vcu: {
      [alertId: string]: AlertData;
    };
    alertData: {
      smart: AlertData[];
      bms: AlertData[];
      mc: AlertData[];
      charger: AlertData[];
      vcu: AlertData[];
    };
    smartCount: number;
    bmsCount: number;
    mcCount: number;
    vcuCount: number;
    chargerCount: number;
    sort: TSort;
    pagination: TPagination;
    alertPagination: TAlertPagination;
    filter: TFilter;
    locationFilter: TFilter;
    vehicleFilter: TFilter;
    timeFrameFilter: TFilter;
    searchFilter: TSearchFilter;
    activeAlertTab: TAlertType;
  };
  alertsCount: {
    smartCount: any,
    bmsCount: any,
    mcCount: any,
    vcuCount: any,
    chargerCount: any
  };

  alertsDownload: {
    smart: {
      [alertId: string]: AlertData;
    };
    bms: {
      [alertId: string]: AlertData;
    };
    mc: {
      [alertId: string]: AlertData;
    };
    vcu: {
      [alertId: string]: AlertData;
    };
    charger: {
      [alertId: string]: AlertData;
    };
    alertData: {
      smart: AlertData[];
      bms: AlertData[];
      mc: AlertData[];
      vcu:AlertData[];
      charger:AlertData[]
    };
    smartCount: number;
    bmsCount: number;
    mcCount: number;
    sort: TSort;
    pagination: TPagination;
    alertPagination: TAlertPagination;
    filter: TFilter;
    locationFilter: TFilter;
    vehicleFilter: TFilter;
    timeFrameFilter: TFilter;
    searchFilter: TSearchFilter;
    activeAlertTab: TAlertType;
  };

  errorPages  : {
    smartStatusCode:any,
    bmsStatusCode:any,
    mcStatusCode:any,
  }

  currentTabName: string;
  trendTotalAlerts: TtrendTotalAlerts;
  trendTop5Alert: TtrendTop5Alert;
  trendLocationWise: any;
  alertInsights: TAlertInsights;
  pastAlerts: {
    data: {
      [alertId: string]: TPastAlertData;
    };
    dataCount: number;
    sort: TSort;
    pagination: TPagination;
  };
  trendsZoom: number;
  graphs: {
    [alertTypeId: string]: any;
  };
  quickSightUrl: string;
  quicksightloading:boolean;
  dashboardList: TDashboardList[];
  mapMarkers: TMapMarkers[]; // map markers in map view map
  dropdownFilters: TDropdownFilters; ///dropdown filters in alerts page
  mapViewDropDownFilters: TMapViewFilters; // dynamic Dropdown Filters in map view
  searchOptions: TSearchOptions[];
  module: TModule[];
  subModule: TSubModule;
  componentData: any;
  dropdownComponentData: any[];
  WidgetcomponentData: any[];
  StatuswidgetcomponentData: any[];
  DescriptionwidgetcomponentData: any[];
  submitResponse: any;
  qsDashboardData: any;
  alertClearedSuccess: boolean;
  getServicesValues: any;
  dynamicComponentSearchValue:string;

  //  this sections contains all the loading states 

  //alerts page
  mainAlertsLoading: boolean;
  total_alerts_loading: boolean,
  top_five_loading: boolean,
  location_alerts_loading: boolean,

  //alert details page
  additional_insights_loading: boolean,
  past_alerts_loading: boolean,
  graphs_loading: boolean,

  // module view type
  view_module_type: string;

  // checked items in table
  checked_table_items: any;
  checked_table_count: any;

  // update the view of the panel
  // show_addition_form :any
  panel_view_mode: any;

  // is_grid_from_dynamodb
  is_grid_from_dynamodb: any;

  // b2b related states
  trip_list: any;
  isTripLoading: boolean;
  b2b_quicklink_visible: boolean;
  b2b_live_vehicle_object: any;

  b2b_trip_data_table_loaded: boolean;
  b2b_grid_data_table_loaded: boolean;
  b2b_trip_table_data: any;
  b2b_grid_table_data: any;

  //b2b charts data
  b2b_vehicle_insights_data: any;
  b2b_vehicle_insights_loading: any;
  b2b_vehicle_insights_loading_error: boolean;
  b2b_trip_insights_data: any;
  b2b_trip_insights_loading: any;
  b2b_battery_insights_data: any;
  b2b_battery_insights_loading: any;
  b2b_battery_insights_loading_error: any;


  // policy mangemene
  show_add_policy_drawer: any;
  module_is_loading: boolean;
  policy_search_keyword: string;
  policy_add_grid_modal: boolean;
  policy_add_geofence_modal: boolean;
  current_policy_tab: string;
  current_policy_name: string;
  policy_edit_grid_modal: boolean;
  store_pricing_data:any;


  // new state parameters for charging station
  csModules: any
  csSubModules: any
  csGridDataSource: any
  csGridDataColumn: any
  csLocationDropDowns: any
  csLocationChosenDropDowns: any
  csPanelIsOpened: any
  csPanelMode: any
  csRowClickValue: any
  stationAddedSuccess: any
  csSearchInput: any
  csModule: any
  csAddStationForm: any
  csViewMode: any,
  csWebComponents: any

  // user profile
  user_profile: any;

   //Device Control Management
   getAllFirmware: any
   getOrgDropDown: any
   getAllAssignedFirmware: any
   getAllIotDevice: any
   searchIotDevice: any
   firmWareList: any
   allJobsList: any
   jobIsLoading: boolean
   getJobDetailsByJobId: any
   jobdetailsloading: boolean
   getAllThingGroup: any
   getThingGroupDetailByName: any
   searchAllGroupDevice: any,
   isThingDataLoading: boolean
   otaHomePageData: any,
 
   allSelectedVCU:{
     selectedVCUlist:{
       VCUDetails:any
       
     }
   }
   allLoadedData:any

//bulk upload


// device group
allDeviceGroupsList:any,
   DeviceGroupIsLoading:boolean,
   getDeviceGroupDetailsByDeviceGroupId:any,
   DeviceGroupdetailsloading:boolean
   getAllDeviceGroup: any
   getDeviceGroupDetailByName: any
   allFilterDeviceList:any
   FilterDeviceIsLoading:boolean
   allFilterDeviceDropdownList:any,
   FilterDeviceDropDownIsLoading:any,
   addDevicesToGroup:any
   getDevicesFromGroup:any,
   DeviceGroupDetailIsLoading:boolean,
 

bulk_upload_history:any   
//swapping station
   swapping_station: {
    console: {
      map_visible: any,
      overview: {
        total_stations: any,
        total_batteries: any,
        total_swaps: any,
        total_consumers: any,
        usage_overview: any,
        slot_distribution: any,
        swap_usage: any,
      },
      search_keyword: string,
      stations_list: any
    },
    station_details: {
      station_data: any,
      battery_list: any,
      swap_history: any,
      booking_details: any
    },
    rider_details: {
      rider_list: any,
      rider_swap_history_loading: boolean,
      rider_swap_history: any
    }
  };
//  Charging Staion states
charging_station: {
  console: {
    map: any;
    charging_station_overview: {
      total_stations: any;
      total_ocpp_tags: any;
      total_users: any;
      no_of_active_resrvations: any;
      no_of_active_transactions: any;
      received_heartbeats: any;
    };
    search_keyword: string;
    charging_stations_list: any;
    ocpp_tag_data: any;
    reservation_data: any;
    transaction_data: any;
    charging_profile_list: any;
    charging_profile_data:{
      profile_data:any,
      connector_data:any,
      Schedule_period_data:any
    },
    charging_station_data: any;
    charge_point_data: any;
    ocpp_tag_details: {
      ocpp_tag_list: any;
    };
  };
};


}

const connectmState: State = {
  user: {
    authenticated: false,
    user: null,
  },
  alerts: {
    smart: {},
    bms: {},
    mc: {},
    vcu: {},
    charger: {},
    alertData: {
      smart: [],
      bms: [],
      mc: [],
      charger:[],
      vcu:[]
    },
    smartCount: 0,
    bmsCount: 0,
    mcCount: 0,
    vcuCount: 0,
    chargerCount:0,
    pagination: {
      pageNumber: 1,
      pageSize: 10,
    },
    sort: {
      fieldName: "Time",
      direction: "ascend",
    },
    alertPagination: {
      smart: {
        pageNumber: 1,
        pageSize: 10,
      },
      bms: {
        pageNumber: 1,
        pageSize: 10,
      },
      mc: {
        pageNumber: 1,
        pageSize: 10,
      },
      charger: {
        pageNumber: 1,
        pageSize: 10,
      },
      vcu: {
        pageNumber: 1,
        pageSize: 10,
      },
    },
    filter: { fieldName: "all", value: "" },
    locationFilter: { fieldName: "all", value: "" },
    vehicleFilter: { fieldName: "all", value: "" },
    timeFrameFilter: { fieldName: "all", value: "" },
    searchFilter: { fieldName: "all", value: "", isVehicle: false },
    activeAlertTab: "smart",
  },
  alertsCount: {
    smartCount: null,
    bmsCount: null,
    mcCount: null,
    vcuCount: null,
    chargerCount: null
  },

  alertsDownload: {
    smart: {},
    bms: {},
    mc: {},
    vcu:{},
    charger:{},
    alertData: {
      smart: [],
      bms: [],
      mc: [],
      vcu:[],
      charger:[],
    },
    smartCount: 0,
    bmsCount: 0,
    mcCount: 0,
    pagination: {
      pageNumber: 1,
      pageSize: 10,
    },
    sort: {
      fieldName: "Time",
      direction: "ascend",
    },
    alertPagination: {
      smart: {
        pageNumber: 1,
        pageSize: 10,
      },
      bms: {
        pageNumber: 1,
        pageSize: 10,
      },
      mc: {
        pageNumber: 1,
        pageSize: 10,
      },
      charger: {
        pageNumber: 1,
        pageSize: 10,
      },
      vcu: {
        pageNumber: 1,
        pageSize: 10,
      },
    },
    filter: { fieldName: "all", value: "" },
    locationFilter: { fieldName: "all", value: "" },
    vehicleFilter: { fieldName: "all", value: "" },
    timeFrameFilter: { fieldName: "all", value: "" },
    searchFilter: { fieldName: "all", value: "", isVehicle: false },
    activeAlertTab: "smart",
   
  },

 
  errorPages  : {
    smartStatusCode:false,
    bmsStatusCode:false,
    mcStatusCode:false,
  },

  currentTabName:"smart",
  trendTotalAlerts: { data: [] },
  trendTop5Alert: { data: [], lines: {} },
  trendLocationWise: { data: [], lines: {} },
  alertInsights: alertInsightsLimpData(),
  pastAlerts: {
    data: {},
    dataCount: 0,
    sort: {
      fieldName: "alertTime",
      direction: "descend",
    },
    pagination: {
      pageNumber: 1,
      pageSize: 10,
    },
  },
  trendsZoom: 0,
  graphs: [],
  quickSightUrl: "",
  quicksightloading:true,
  dashboardList: [],
  mapMarkers: [],
  dropdownFilters: {
    location: [],
    vehicle: [],
  },
  mapViewDropDownFilters: { customer: [], location: [], region: [] },
  searchOptions: [],
  module: [],
  subModule: {
    group_1: [],
    group_2: [],
    group_3: [],
    group_4: [],
    group_5: [],
    group_6: [],
    group_7: [],
    group_8: [],
    group_9: [],
    group_10: [],
    group_11: [],
    group_12: []
  },
  componentData: {},
  dropdownComponentData: [],
  WidgetcomponentData: [],
  StatuswidgetcomponentData: [],
  DescriptionwidgetcomponentData: [],
  submitResponse: {},
  qsDashboardData: {},
  alertClearedSuccess: false,
  getServicesValues: "",
  dynamicComponentSearchValue:"",


  //  this sections contains all the loading states 
  mainAlertsLoading: true,
  total_alerts_loading: true,
  top_five_loading: true,
  location_alerts_loading: true,

  // alert details page
  additional_insights_loading: true,
  past_alerts_loading: true,
  graphs_loading: true,

  // view_module_type
  view_module_type: "V",

  // checked table items
  checked_table_items: [],
  checked_table_count: 0,

  // There can be four panel view modes
  // 1. ADD
  // 2. CUSTOM_ADD
  // 3. EDIT
  // 4. CUSTOM_EDIT
  panel_view_mode: "",

  is_grid_from_dynamodb: false,

  // B2b related states
  trip_list: null,
  isTripLoading: false,
  b2b_quicklink_visible: false,
  b2b_live_vehicle_object: {},
  b2b_trip_data_table_loaded: false,
  b2b_grid_data_table_loaded: false,
  b2b_trip_table_data: {},
  b2b_grid_table_data: {},


  //b2b charts data
  b2b_vehicle_insights_data: {},
  b2b_vehicle_insights_loading: true,
  b2b_vehicle_insights_loading_error: false,
  b2b_trip_insights_data: {},
  b2b_trip_insights_loading: true,
  b2b_battery_insights_data: {},
  b2b_battery_insights_loading: true,
  b2b_battery_insights_loading_error: false,


  // policy mangement states
  show_add_policy_drawer: false,
  module_is_loading: true,
  policy_search_keyword: "",
  policy_add_grid_modal: false,
  policy_add_geofence_modal: false,
  current_policy_tab: "",
  current_policy_name: "",
  policy_edit_grid_modal: false,
  store_pricing_data:[],

  // new state parameters for charging station
  csModules:{},
  csSubModules:{},
  csGridDataSource:[],
  csGridDataColumn:[],
  csLocationDropDowns:[],
  csLocationChosenDropDowns:{},
  csPanelIsOpened:false,
  csPanelMode:"",
  csRowClickValue:{},
  stationAddedSuccess:false,
  csSearchInput:"",
  csModule:"Stations",
  csAddStationForm:{},
  csViewMode:"",
  csWebComponents:{},

 //Device Control Management

 getAllFirmware: {},
 getOrgDropDown: {},
 getAllAssignedFirmware: {},
 getAllIotDevice: {},
 searchIotDevice: "",
 firmWareList: {},
 allJobsList: {},
 jobIsLoading: true,
 getJobDetailsByJobId: {},
 jobdetailsloading: true,
 getAllThingGroup: {},
 getThingGroupDetailByName: {},
 searchAllGroupDevice: {},
 isThingDataLoading: true,
 otaHomePageData: {},
 allSelectedVCU:{
   selectedVCUlist:{
     VCUDetails:[]
     
   }
 },
 allLoadedData:{},
 //bulk upload
 bulk_upload_history:"",
 
 //device group
 
 allDeviceGroupsList:{},
 DeviceGroupIsLoading:true,
 getDeviceGroupDetailsByDeviceGroupId:{},
 DeviceGroupdetailsloading:true,
 getAllDeviceGroup: {},
 getDeviceGroupDetailByName: {},
 allFilterDeviceList:{},
 FilterDeviceIsLoading:true,
 allFilterDeviceDropdownList:{},
 FilterDeviceDropDownIsLoading:true,
 addDevicesToGroup:{},
 getDevicesFromGroup:{},
 DeviceGroupDetailIsLoading:true,
 
 //swapping station state
swapping_station: {
  console: {
    map_visible: [],
    overview: {
      total_stations: "-",
      total_batteries: "-",
      total_swaps: "-",
      total_consumers: "-",
      usage_overview: [],
      slot_distribution: [],
      swap_usage: []
    },
    search_keyword: "",
    stations_list: []
  },
  station_details: {
    station_data: [],
    battery_list: [],
    swap_history: [],
    booking_details: []
  },
  rider_details: {
    rider_list: [],
    rider_swap_history_loading: true,
    rider_swap_history: []
  }
},
// charging station
charging_station: {
  console: {
    map: [],
    charging_station_overview: {
      total_stations: "",
      total_ocpp_tags: "",
      total_users: "",
      no_of_active_resrvations: "",
      no_of_active_transactions: "",
      received_heartbeats: "0",
    },
    search_keyword: "",
    charging_stations_list: [],
    ocpp_tag_data: [],
    reservation_data: [],
    transaction_data: [],
    charging_profile_list: [],
    charging_profile_data:{
      profile_data:[],
      connector_data:[],
      Schedule_period_data:[]
    },      
    charging_station_data: [],
    charge_point_data: [],
    ocpp_tag_details: {
      ocpp_tag_list: [],
    },
  },
},

  // user profile
  user_profile: {},
};

export default connectmState;

export function alertInsightsLimpData() {
  const alertInsights: TAlertInsights = {
    avgMileageInKm: "N/A",
    avgRangeRideInKm: "N/A",
    ridesPerMnthInKm: "N/A",
    totalDistInKm: "N/A",
    utilization: "N/A",
  };
  return alertInsights;
}

export function alertLimpData() {
  const alert: AlertData = {
    severity: "-1",
    customerName: "N/A",
    alertId: -1,
    alertName: "N/A",
    alertTime: "N/A",
    batteryId: "N/A",
    deviceId: "N/A",
    customerId: "N/A",
    frameId: "N/A",
    location: "N/A",
    mfgDate: "N/A",
    model: "N/A",
    openSince: "N/A",
    alertCode: "N/A",
    alertTypeId: -1,
    alertType: "N/A",
    alarmValue: "N/A",
    receivedTime:"N/A",
  };
  return alert;
}