import { IAlertGraphActions } from "../actions/graph";
import axios from "axios";
import { call, put } from "redux-saga/effects";
import { getToken } from "../authentication";
import moment from "moment-timezone";

export type Store_AlertGraph = {
  type: "STORE_ALERT_GRAPH";
  payload: {
    alertTypeId: number;
    data: any;
  };
};

export type Store_AlertGraph_Clear = {
  type: "STORE_ALERT_GRAPH_CLEAR";
  payload: {
    alertTypeId: number;
    data: any;
  };
};

export function* getAlertGraphDatas(params: IAlertGraphActions) {
  try {
    const data = yield call(getAlertGraphData, params);
    // empty the existing error object
    yield put({
      type: "STORE_ALERT_GRAPH_CLEAR",
      payload: {
        alertTypeId: 0,
        data: [],
      },
    } as Store_AlertGraph_Clear);
    yield put({
      type: "STORE_ALERT_GRAPH",
      payload: {
        alertTypeId: params.payload.alertTypeId,
        data: data,
      },
    } as Store_AlertGraph);
  } catch (error) {
    console.log("error", error);
  }
}

export function* clearAlertGraphDatas(params: IAlertGraphActions) {
  yield put({
    type: "STORE_ALERT_GRAPH",
    payload: {
      alertTypeId: params.payload.alertTypeId,
      data: undefined,
    },
  } as Store_AlertGraph);
}

export async function getAlertGraphData(params: IAlertGraphActions) {
  const data = await getGraphData(params);
  return data;
}

// async function getGraphData(params: IAlertGraphActions) {
//   let response: any = [];
//   response = await axios.get(process.env.REACT_APP_WEBAPIURL + "/graphs", {
//     params: {
//       vehicleId: params.payload.vehicleId,
//       alertId: params.payload.alertId,
//       alertName: params.payload.alertName,
//       alertTypeId: params.payload.alertTypeId,
//       timeStamp: params.payload.timeStamp,
//       alertCode: params.payload.alertCode,
//     },
//   });
//   return response.data.body;
// }

async function getGraphData(params: IAlertGraphActions) {
  const tokenRes = await getToken();
  let response: any = [];
  response = await axios.post(
    process.env.REACT_APP_WEBAPPAPI + "/getalertsgraphdata",
    {
      vehicleId: localStorage.getItem("grpvehicleId"),
      alertId: localStorage.getItem("grpalertId"),
      alertName: localStorage.getItem("grpalertName"),
      alertTypeId: localStorage.getItem("grpalertTypeId"),
      timeStamp: localStorage.getItem("grpalerteventutc"),
      // localStorage.getItem('grptimeStamp')
      alertCode: localStorage.getItem("grpalertCode"),
      org_id: params.payload.org_id,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenRes.token!,
      },
    }
  );
  return response.data.data;
}
const formatDateTime = (time: string | any, format: string) => {
  console.log("GRAPHTIME : ", time, "FORMAT", format);
  // const newDate = new Date(time)
  // // const localAlertTime = moment(newDate).format(format)
  // const localAlertTime = moment.utc(newDate).local().format(format)
  // moment(newDate).format("DD-MMM-YYYY HH:mm A")
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const parsedTime = moment.tz(time, "DD-MMM-YYYY hh:mm A", browserTimeZone);
  const formattedTime = parsedTime.format("YYYY-MM-DDTHH:mm:ss.SSS");

  // const parsedDate = moment(time, "DD-MMM-YYYY hh:mm A");
  // const formattedDateUTC = parsedDate.utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ");

  const parsedDate = moment.tz(time, 'DD-MMM-YYYY hh:mm A', browserTimeZone);
const utcDate = parsedDate.utc();
const formattedDate = utcDate.format('YYYY-MM-DDTHH:mm:ss.SSS[+0000]');
  console.log(
    `formattedTime : ${formattedTime} 
    formattedDate :${formattedDate}
    parsedTime :${parsedTime}`
  );
  return formattedDate;
};
