import connectmState, { State } from "./connectm-state";
import {
  IUsersAction,
  IStoreGetServices,
  IUpdateUserProfile,
} from "../actions/user";
import {
  Store_AlertUpdate,
  Store_AlertTabChange,
  Store_AlertFilterChange,
  Store_DropdownFilters,
  Store_AlertUpdateCount,
  StoreFilteredAlertCount,
  Store_AlertUpdate_Download,
} from "../saga/alert";
import { Store_GetAlertTrends, Store_UpdateALertTrends } from "../saga/trends";
import { Store_AlertGraph, Store_AlertGraph_Clear } from "../saga/graph";
import { Store_UserUpdate } from "../saga/user";
import {
  Store_AlertInsights,
  Store_PastAlert,
  Store_UpdatePastAlert,
  Store_UpdateSingleAlert,
  Store_ClearSingleAlert,
  Store_AlertClearReducer,
} from "../saga/alert-detail";
import { Store_QuickSightUrl, Clear_QuickSightUrl } from "../saga/quickSight";
import { Store_DashboardList, Clear_DashboardList } from "../saga/dashboard";
import {
  Store_CmdService,
  Store_CmdSubModule,
  Store_ComponentData,
  Store_widget_ComponentData,
  Store_dropdown_ComponentData,
  Store_status_widget_ComponentData,
  Store_description_widget_ComponentData,
  Store_qs_DashboardData,
  Store_Submit_Response,
  clear_module_ComponentData,
} from "../saga/cmd-details";
import { Store_MapMarkers, Store_MapViewFilters } from "../saga/map";
import { Store_SearchOptions } from "../saga/search";
import { ClearSingleAlert } from "../actions/alert-detail";
import { ISetAlertClearAction } from "../actions/alert-detail";
import {
  IResetMainAlertsLoading,
  ISingleAlertsLoading,
  IUpdateCurrentAlert,
} from "../actions/alerts";
import {
  UpdateDynamicSearch,
  IupdateModuleView,
  IsendCheckedItem,
  IUpdatePanelViewMode,
  IModuleLoading,
  IBulkUploadHistory,
} from "../actions/cmd-details-action";
import {
  IGridFromDynamodb,
  IGetAllLoadedData,
} from "../actions/customerServiceActions";
import {
  IUpdateTripList,
  ITripLoadingStatus,
  IB2BQucikLinkVisible,
  IUpdateLiveVehicleObject,
  IGridDataLoadedStatus,
  ITripDataLoadedStatus,
  IInsertB2bTripData,
  IInsertB2bGridData,
} from "../actions/b2b-module";
import {
  IStoreVehicleInsightsError,
  IStoreBatteryInsightsError,
  ILoadingVehicleInsightsChart,
  ILoadingBatteryInsightsChart,
  IgetVehicleInsightsCharts,
  IgetBatteryInsightsCharts,
  IgetTripInsightsCharts,
} from "./../saga/b2b-charts";
import {
  IResetAllPolicyModals,
  ITogglePolicyEditGridModal,
  ICurrentPolicyName,
  ICurrentPolicyTab,
  IShowAddPolicyDrawer,
  IPolicySearchKeyword,
  IEmptyPolicyGrid,
  ITogglePolicyAddGeofenceModal,
  ITogglePolicyAddGridModal,
  IPolicyGeofence,
  StorePricingData,
} from "../actions/policy-actions";

import {
  IJobIsLoading,
  IUpdateJobDetailsLoading,
  IStoreHomePage,
  IupdateThingLoading,
  IFirmwareLIstDropDown,
  IGetAllAssignedFirmWare,
  IGetAllDevices,
  IGetAllFirmWare,
  IgetAllIotDevice,
  IgetAllJobs,
  IGetAllThingGroups,
  IGetJobDetailsByJobId,
  IgetOrgs,
  IGetThingGroupDetailsByName,
  ISearchAllThingGroup,
  ISearchIotDevices,
  IUploadSelectedVCU,
  IUpdateDeviceGroupDetailsLoading,
  IDeviceGroupIsLoading,
  IgetAllDeviceGroups,
  IGetDeviceGroupDetailsByDeviceGroupId,
  IFilterDeviceIsLoading,
  IgetAllFilterDevices,
  IgetAllFilterDeviceDropdownList,
  IAddDevicesToGroup,
  IGetDevicesfromGroup,
} from "../actions/device-control";
import {
  IStoreOverviewCountConsumers,
  IGetRidersHistoryLoading,
  IStoreSwapHistoryByUserId,
  IStoreGetRidersList,
  IStoreSwapUsageForPast7days,
  ISwappingStationSearchKeyword,
  IStoreSwapHistoryByStationId,
  IStoreBookingDetailsByStationId,
  IStoreBatteryListBYStationId,
  IStoreStationDetailsById,
  ISwappingStationShowMapView,
  IStoreUsageOverview,
  IStoreSlotDistribution,
  IStoreOverviewCountStations,
  IStoreOverviewCountBatteries,
  IStoreOverviewCountSwaps,
  IStoreSwappingStationLists,
} from "./../actions/swappingStation/swapping-station";
import {
  StoreChargingStationList,
  ChargingStationSearchKeyword,
  GetChargingProfileDetails,
  StoreChargingProfileDetails,
  StoreChargingStationDetailsById,
  ChargingStationMapView,
  StoreReservationData,
  StoreTransactionData,
  StoreOCPPTagData,
  UpdateOcppDetailsById,
  StoreChargePointDetails,
  StoreChargingProfileDetailsById,
  StoreConnectorData,
  StoreschedulePeriodByProfileId,
} from "./../actions/chargingstation/index";
import { IQuickSightLoading } from "./../actions/quickSight";
type ActionParams =
  | IUsersAction
  | Store_AlertUpdate
  | Store_AlertUpdate_Download
  | Store_AlertTabChange
  | Store_AlertFilterChange
  | Store_AlertInsights
  | Store_GetAlertTrends
  | Store_PastAlert
  | Store_UpdatePastAlert
  | Store_UpdateALertTrends
  | Store_AlertGraph
  | Store_AlertGraph_Clear
  | Store_UpdateSingleAlert
  | Store_UserUpdate
  | Store_QuickSightUrl
  | Store_DashboardList
  | Clear_DashboardList
  | Clear_QuickSightUrl
  | Store_MapMarkers
  | Store_DropdownFilters
  | Store_MapViewFilters
  | Store_SearchOptions
  | Store_CmdService
  | Store_ComponentData
  | Store_widget_ComponentData
  | Store_status_widget_ComponentData
  | Store_description_widget_ComponentData
  | Store_dropdown_ComponentData
  | Store_Submit_Response
  | clear_module_ComponentData
  | Store_qs_DashboardData
  | Store_CmdSubModule
  | Store_ClearSingleAlert
  | Store_AlertClearReducer
  | ISetAlertClearAction
  | IResetMainAlertsLoading
  | ISingleAlertsLoading
  | Store_AlertUpdateCount
  | StoreFilteredAlertCount
  | IStoreGetServices
  | IupdateModuleView
  | IsendCheckedItem
  | IUpdatePanelViewMode
  | IGridFromDynamodb
  | IGetAllLoadedData
  | IUpdateTripList
  | ITripLoadingStatus
  | IB2BQucikLinkVisible
  | IUpdateLiveVehicleObject
  | IGridDataLoadedStatus
  | ITripDataLoadedStatus
  | IInsertB2bTripData
  | IInsertB2bGridData
  | IUpdateUserProfile
  | IgetVehicleInsightsCharts
  | IgetBatteryInsightsCharts
  | IgetTripInsightsCharts
  | ILoadingVehicleInsightsChart
  | ILoadingBatteryInsightsChart
  | IStoreVehicleInsightsError
  | IStoreBatteryInsightsError
  | IShowAddPolicyDrawer
  | IModuleLoading
  | IPolicySearchKeyword
  | IEmptyPolicyGrid
  | ITogglePolicyAddGridModal
  | ITogglePolicyAddGeofenceModal
  | ICurrentPolicyTab
  | ICurrentPolicyName
  | ITogglePolicyEditGridModal
  | IResetAllPolicyModals
  // | ICSModuleChange
  // | IGetStationForm
  // | StoreFilteredAlertCount
  // | IGetCSModules
  // | IGetCSModuleChange
  // | IGetCSGridDataSource
  // | IclearStationData
  // | IUpdateCurrentModule
  // | IchangeViewMode
  // | IStoreCSWebComponents
  // | IStoreGetServices
  // | IStationSearch
  // | IFilterGridData
  // | IStoreCsSubComponents
  // | IStoreCsGridData
  // | IStoreCsGridColumn
  // | IStoreCsLocationDROPDOWN
  // | IGetCsSubComponents
  // | IUpdateChargingStation
  // | IInsertNewStationStatus
  // | IInsertNewStation
  // | ICSPanelOpen
  // | ISetPanelMode
  // | ISetRowClickValue
  | IQuickSightLoading
  | UpdateDynamicSearch
  | IUpdateCurrentAlert
  | IStoreSwapUsageForPast7days
  | ISwappingStationSearchKeyword
  | IStoreSwapHistoryByStationId
  | IStoreBookingDetailsByStationId
  | IStoreBatteryListBYStationId
  | IStoreStationDetailsById
  | ISwappingStationShowMapView
  | IStoreUsageOverview
  | IStoreSlotDistribution
  | IStoreOverviewCountStations
  | IStoreOverviewCountBatteries
  | IStoreOverviewCountSwaps
  | IStoreSwappingStationLists
  | IStoreGetRidersList
  | IStoreSwapHistoryByUserId
  | IGetRidersHistoryLoading
  | IStoreOverviewCountConsumers
  | IGetAllFirmWare
  | IgetOrgs
  | IGetAllAssignedFirmWare
  | IgetAllIotDevice
  | ISearchIotDevices
  | IFirmwareLIstDropDown
  | IgetAllJobs
  | IGetJobDetailsByJobId
  | IGetAllThingGroups
  | IGetThingGroupDetailsByName
  | ISearchAllThingGroup
  | IupdateThingLoading
  | IStoreHomePage
  | IUpdateJobDetailsLoading
  | IGetAllDevices
  | IJobIsLoading
  | IUploadSelectedVCU
  | IUpdateDeviceGroupDetailsLoading
  | IDeviceGroupIsLoading
  | IgetAllDeviceGroups
  | IFilterDeviceIsLoading
  | IgetAllFilterDevices
  | IGetDeviceGroupDetailsByDeviceGroupId
  | IgetAllFilterDeviceDropdownList
  | IAddDevicesToGroup
  | IGetDevicesfromGroup
  | StoreChargingStationList
  | ChargingStationSearchKeyword
  | GetChargingProfileDetails
  | StoreChargingProfileDetails
  | StoreChargingStationDetailsById
  | ChargingStationMapView
  | StoreReservationData
  | StoreTransactionData
  | StoreOCPPTagData
  | UpdateOcppDetailsById
  | StoreChargePointDetails
  | StoreChargingProfileDetailsById
  | StoreConnectorData
  | StoreschedulePeriodByProfileId
  // | IStoreOrganisation
  // | IStoreAllRoles
  // | IStoreGetServicesList
  // | IStoreModuleByServiceid
  // | IStoreGetSubModuleByOrgAndModule
  // | IGetPaymentDetails
  // | RefundModelSearchKeyword
  // | RefundModelRideIdSearchKeyword
  // | StorePaymentDetailsList
  // | StoreRideIDList
  | IBulkUploadHistory
  | IPolicyGeofence
  | StorePricingData
  | StoreChargingStationList
  | ChargingStationSearchKeyword
  | GetChargingProfileDetails
  | StoreChargingProfileDetails
  | StoreChargingStationDetailsById
  | ChargingStationMapView
  | StoreReservationData
  | StoreTransactionData
  | StoreOCPPTagData
  | UpdateOcppDetailsById
  | StoreChargePointDetails
  | StoreChargingProfileDetailsById
  | StoreConnectorData
  | StoreschedulePeriodByProfileId;

const AppReducer = (
  state: State = connectmState,
  actionParams: ActionParams
) => {
  switch (actionParams.type) {
    case "RECEIVED_USER": {
      return {
        ...state,
        user: actionParams.payload,
      };
    }
    case "STORE_ALERT_UPDATE": {
      const smartAlertData = Object.assign(
        {},
        ...(actionParams as Store_AlertUpdate).payload.alerts.smart.data.map(
          (alert) => {
            return {
              [String(alert.alertId)]: alert,
            };
          }
        )
      );
      const bmsAlertData = Object.assign(
        {},
        ...(actionParams as Store_AlertUpdate).payload.alerts.bms.data.map(
          (alert) => {
            return {
              [String(alert.alertId)]: alert,
            };
          }
        )
      );
      const mcAlertData = Object.assign(
        {},
        ...(actionParams as Store_AlertUpdate).payload.alerts.mc.data.map(
          (alert) => {
            return {
              [String(alert.alertId)]: alert,
            };
          }
        )
      );

      return {
        ...state,
        alerts: {
          ...state.alerts,
          smart: smartAlertData,
          bms: bmsAlertData,
          mc: mcAlertData,
          vcu: mcAlertData,
          charger: mcAlertData,
          alertData: {
            smart: (actionParams as Store_AlertUpdate).payload.alerts.smart
              .data,
            bms: (actionParams as Store_AlertUpdate).payload.alerts.bms.data,
            mc: (actionParams as Store_AlertUpdate).payload.alerts.mc.data,
            vcu: (actionParams as Store_AlertUpdate).payload.alerts.vcu.data,
            charger: (actionParams as Store_AlertUpdate).payload.alerts.charger
              .data,
          },
          pagination: (actionParams as Store_AlertUpdate).payload
            .alertPagination[
            (actionParams as Store_AlertUpdate).payload.alertType
          ],
          sort: (actionParams as Store_AlertUpdate).payload.sort,
          smartCount: (actionParams as Store_AlertUpdate).payload.alerts.smart
            .dataCount,
          bmsCount: (actionParams as Store_AlertUpdate).payload.alerts.bms
            .dataCount,
          mcCount: (actionParams as Store_AlertUpdate).payload.alerts.mc
            .dataCount,
          vcuCount: (actionParams as Store_AlertUpdate).payload.alerts.vcu
            .dataCount,
          chargerCount: (actionParams as Store_AlertUpdate).payload.alerts
            .charger.dataCount,
          activeAlertTab: (actionParams as Store_AlertUpdate).payload.alertType,
          alertPagination: {
            ...(actionParams as Store_AlertUpdate).payload.alertPagination,
          },
        },
        mainAlertsLoading: false,
      };
    }

    case "STORE_ALERT_UPDATE_DOWNLOAD": {
      const smartAlertData = Object.assign(
        {},
        ...(
          actionParams as Store_AlertUpdate_Download
        ).payload.alerts.smart.data.map((alert) => {
          return {
            [String(alert.alertId)]: alert,
          };
        })
      );
      const bmsAlertData = Object.assign(
        {},
        ...(
          actionParams as Store_AlertUpdate_Download
        ).payload.alerts.bms.data.map((alert) => {
          return {
            [String(alert.alertId)]: alert,
          };
        })
      );
      const mcAlertData = Object.assign(
        {},
        ...(
          actionParams as Store_AlertUpdate_Download
        ).payload.alerts.mc.data.map((alert) => {
          return {
            [String(alert.alertId)]: alert,
          };
        })
      );
      return {
        ...state,
        alertsDownload: {
          ...state.alerts,
          smart: smartAlertData,
          bms: bmsAlertData,
          mc: mcAlertData,
          vcu: mcAlertData,
          charger: mcAlertData,
          alertData: {
            smart: (actionParams as Store_AlertUpdate_Download).payload.alerts
              .smart.data,
            bms: (actionParams as Store_AlertUpdate_Download).payload.alerts.bms
              .data,
            mc: (actionParams as Store_AlertUpdate_Download).payload.alerts.mc
              .data,
            vcu: (actionParams as Store_AlertUpdate_Download).payload.alerts.mc
              .data,
            charger: (actionParams as Store_AlertUpdate_Download).payload.alerts
              .mc.data,
          },
          pagination: (actionParams as Store_AlertUpdate_Download).payload
            .alertPagination[
            (actionParams as Store_AlertUpdate_Download).payload.alertType
          ],
          sort: (actionParams as Store_AlertUpdate_Download).payload.sort,
          smartCount: (actionParams as Store_AlertUpdate_Download).payload
            .alerts.smart.dataCount,
          bmsCount: (actionParams as Store_AlertUpdate_Download).payload.alerts
            .bms.dataCount,
          mcCount: (actionParams as Store_AlertUpdate_Download).payload.alerts
            .mc.dataCount,
          vcuCount: (actionParams as Store_AlertUpdate_Download).payload.alerts
            .vcu.dataCount,
          chargerCount: (actionParams as Store_AlertUpdate_Download).payload
            .alerts.charger.dataCount,
          activeAlertTab: (actionParams as Store_AlertUpdate_Download).payload
            .alertType,
          alertPagination: {
            ...(actionParams as Store_AlertUpdate_Download).payload
              .alertPagination,
          },
        },
        errorPages: {
          smartStatusCode: (actionParams as any).payload.alerts.smart.status,
          bmsStatusCode: (actionParams as any).payload.alerts.bms.status,
          mcStatusCode: (actionParams as any).payload.alerts.mc.status,
          vcuStatusCode: (actionParams as any).payload.alerts.vcu.status,
          chargerStatusCode: (actionParams as any).payload.alerts.charger
            .status,
        },
        mainAlertsLoading: false,
      };
    }
    case "STORE_ALERT_COUNT_UPDATE": {
      console.log("countdata", actionParams.payload);
      return {
        ...state,
        alertsCount: {
          ...state.alertsCount,
          smartCount: actionParams.payload.smartCount.totalNoOfElements,
          bmsCount: actionParams.payload.bmsCount.totalNoOfElements,
          mcCount: actionParams.payload.mcCount.totalNoOfElements,
          vcuCount: actionParams.payload.vcuCount.totalNoOfElements,
          chargerCount: actionParams.payload.chargerCount.totalNoOfElements,
        },
      };
    }

    case "UPDATE_CURRENT_ALERT": {
      return {
        ...state,
        currentTabName: actionParams.payload,
      };
    }

    case "STORE_ALERT_TAB_CHANGE": {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          activeAlertTab: (actionParams as Store_AlertTabChange).payload
            .alertType,
          pagination: (actionParams as Store_AlertTabChange).payload.pagination,
          alertPagination: (actionParams as Store_AlertTabChange).payload
            .alertPagination,
        },
      };
    }
    case "STORE_UPDATE_FILTER": {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          filter: (actionParams as Store_AlertFilterChange).payload.filter,
          locationFilter: (actionParams as Store_AlertFilterChange).payload
            .locationFilter,
          vehicleFilter: (actionParams as Store_AlertFilterChange).payload
            .vehicleFilter,
          // productStatus: (actionParams as Store_AlertFilterChange).payload
          //   .productStatus,
          timeFrameFilter: (actionParams as Store_AlertFilterChange).payload
            .timeFrameFilter,
          searchFilter: (actionParams as Store_AlertFilterChange).payload
            .searchFilter,
          pagination: (actionParams as Store_AlertFilterChange).payload
            .pagination,
          alertPagination: (actionParams as Store_AlertFilterChange).payload
            .alertPagination,
        },
      };
    }
    case "STORE_GET_ALERT_TRENDS": {
      return {
        ...state,
        trendTotalAlerts: (actionParams as Store_GetAlertTrends).payload
          .trendTotalAlert,
        trendTop5Alert: (actionParams as Store_GetAlertTrends).payload
          .trendTop5Alert,
        trendLocationWise: (actionParams as Store_GetAlertTrends).payload
          .trendLocationWise,
        total_alerts_loading: false,
        top_five_loading: false,
        location_alerts_loading: false,
      };
    }
    case "STORE_ALERTS_INSIGHTS": {
      return {
        ...state,
        alertInsights: (actionParams as Store_AlertInsights).payload
          .alertInsight,
        additional_insights_loading: false,
      };
    }

    case "STORE_PAST_ALERTS": {
      const pastAlerts = Object.assign(
        {},
        ...(actionParams as Store_PastAlert).payload.pastAlert.data.map(
          (alert) => {
            return {
              [String(alert.alertId)]: alert,
            };
          }
        )
      );
      return {
        ...state,
        pastAlerts: {
          ...state.pastAlerts,
          pagination: (actionParams as Store_PastAlert).payload.pagination,
          sort: (actionParams as Store_PastAlert).payload.sort,
          data: pastAlerts,
          dataCount: (actionParams as Store_PastAlert).payload.pastAlert
            .dataCount,
        },
        past_alerts_loading: false,
      };
    }
    case "STORE_UPDATE_PAST_ALERTS": {
      const pastAlerts = Object.assign(
        {},
        ...(actionParams as Store_UpdatePastAlert).payload.pastAlert.data.map(
          (alert) => {
            return {
              [String(alert.alertId)]: alert,
            };
          }
        )
      );
      return {
        ...state,
        pastAlerts: {
          ...state.pastAlerts,
          pagination: (actionParams as Store_UpdatePastAlert).payload
            .pagination,
          sort: (actionParams as Store_UpdatePastAlert).payload.sort,
          data: pastAlerts,
          dataCount: (actionParams as Store_UpdatePastAlert).payload.pastAlert
            .dataCount,
        },
      };
    }
    case "STORE_ALERT_UPDATE_TRENDS": {
      return {
        ...state,
        trendTotalAlerts: (actionParams as Store_UpdateALertTrends).payload
          .trendTotalAlert,
        trendTop5Alert: (actionParams as Store_UpdateALertTrends).payload
          .trendTop5Alert,
        trendLocationWise: (actionParams as Store_UpdateALertTrends).payload
          .trendLocationWise,
        trendsZoom: (actionParams as Store_UpdateALertTrends).payload
          .trendsZoom,
      };
    }
    case "STORE_ALERT_GRAPH_CLEAR": {
      return {
        ...state,
        graphs: {},
      };
    }

    case "STORE_ALERT_GRAPH": {
      return {
        ...state,
        graphs: {
          ...state.graphs,
          [String(actionParams.payload.alertTypeId)]: actionParams.payload.data,
        },
        graphs_loading: false,
      };
    }
    case "STORE_UPDATE_SINGLE_ALERT": {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          [actionParams.payload.alertType]: {
            [actionParams.payload.alertId]: actionParams.payload.alertData,
          },
        },
      };
    }
    case "STORE_USER_UPDATE": {
      return {
        ...state,
        user: actionParams.payload,
      };
    }
    case "STORE_QUICKSIGHTURL": {
      return {
        ...state,
        quickSightUrl: (actionParams as Store_QuickSightUrl).payload
          .quickSightUrl,
        quicksightloading: false,
      };
    }
    case "CLEAR_QUICKSIGHT_URL": {
      return {
        ...state,
        quickSightUrl: "",
      };
    }
    case "STORE_DASHBOARDLIST": {
      return {
        ...state,
        dashboardList: (actionParams as Store_DashboardList).payload
          .dashboardList,
      };
    }
    case "STORE_MAPMARKERS": {
      return {
        ...state,
        mapMarkers: (actionParams as Store_MapMarkers).payload.mapMarkers,
      };
    }
    case "CLEAR_DASHBOARDLIST": {
      return {
        ...state,
        dashboardList: [],
      };
    }
    case "STORE_DROPDOWN_FILTERS": {
      return {
        ...state,
        dropdownFilters: (actionParams as Store_DropdownFilters).payload,
      };
    }
    case "STORE_MAPVIEWFILTERS": {
      return {
        ...state,
        mapViewDropDownFilters: (actionParams as Store_MapViewFilters).payload
          .mapViewDropDownFilters,
      };
    }
    case "STORE_SEARCH_OPTIONS": {
      return {
        ...state,
        searchOptions: (actionParams as Store_SearchOptions).payload
          .searchOptions,
      };
    }
    case "STORE_MODULES": {
      // console.log(actionParams.payload.customerState);
      //connectmState.customerState=[]

      return {
        ...state,
        module: (actionParams as Store_CmdService).payload.module,
      };
    }

    case "GET_ALL_LOADED_DATA": {
      return {
        ...state,
        allLoadedData: actionParams.payload,
      };
    }
    case "STORE_SUB_MODULES": {
      connectmState.dropdownComponentData = [];
      connectmState.componentData = [];
      connectmState.WidgetcomponentData = [];
      connectmState.StatuswidgetcomponentData = [];
      connectmState.DescriptionwidgetcomponentData = [];
      connectmState.qsDashboardData = [];
      return {
        ...state,
        subModule: (actionParams as Store_CmdSubModule).payload.subModule,
      };
    }
    case "STORE_COMPONENT_DATA": {
      // console.log(actionParams);
      return {
        ...state,
        componentData: actionParams.payload.componentData,
      };
    }
    case "STORE_WIDGET_COMPONENT_DATA": {
      // console.log(actionParams);
      return {
        ...state,
        WidgetcomponentData: actionParams.payload.WidgetcomponentData,
      };
    }
    case "STORE_STATUS_WIDGET_COMPONENT_DATA": {
      return {
        ...state,
        StatuswidgetcomponentData:
          actionParams.payload.StatuswidgetcomponentData,
      };
    }
    case "STORE_DESCRIPTION_WIDGET_COMPONENT_DATA": {
      // console.log(actionParams);
      return {
        ...state,
        DescriptionwidgetcomponentData:
          actionParams.payload.DescriptionwidgetcomponentData,
      };
    }
    case "STORE_QS_DASHBOARD_DATA": {
      // console.log(actionParams.payload.qsDashboardData, "reducer");
      return {
        ...state,
        qsDashboardData: actionParams.payload.qsDashboardData,
      };
    }
    case "STORE_DROPDOWN_COMPONENT_DATA": {
      return {
        ...state,
        dropdownComponentData: actionParams.payload.dropdownComponentData,
      };
    }
    case "STORE_SUBMIT_RESPONSE": {
      return {
        ...state,
        submitResponse: actionParams.payload.submitResponse,
      };
    }
    case "CLEAR_MODULE_COMPONENT_DATA": {
      return {
        ...state,
        dropdownComponentData: [],
        componentData: [],
        WidgetcomponentData: [],
        StatuswidgetcomponentData: [],
        DescriptionwidgetcomponentData: [],
        qsDashboardData: {},
        module: [],
      };
    }
    // case "CLEAR_SINGLE_ALERT":{
    //   return{
    //     ...state,
    //     alerts:{
    //       ...state.alerts,
    //       alertData: {
    //         //smart: (actionParams as Store_ClearSingleAlert).payload.alerts.smart.data.filter((item:any) => item.alertId != actionParams.payload.alertId),
    //         smart: (actionParams as Store_ClearSingleAlert).payload.alerts.alertData.smart.filter((item:any) => item.alertId != actionParams.payload.alertId),
    //         bms: (actionParams as Store_ClearSingleAlert).payload.alerts.alertData.bms.filter((item:any) => item.alertId != actionParams.payload.alertId),
    //         mc: (actionParams as Store_ClearSingleAlert).payload.alerts.alertData.mc.filter((item:any) => item.alertId != actionParams.payload.alertId),
    //       },
    //     },
    //   }
    // }
    case "CLEAR_ALERT_REDUCER": {
      if (actionParams.payload.didAlertClear === "true") {
        return {
          ...state,
          alerts: {
            ...state.alerts,
            alertData: {
              //smart: (actionParams as Store_ClearSingleAlert).payload.alerts.smart.data.filter((item:any) => item.alertId != actionParams.payload.alertId),
              smart: state.alerts.alertData.smart.filter(
                (item: any) => item.alertId != actionParams.payload.alertId
              ),
              bms: state.alerts.alertData.bms.filter(
                (item: any) => item.alertId != actionParams.payload.alertId
              ),
              mc: state.alerts.alertData.mc.filter(
                (item: any) => item.alertId != actionParams.payload.alertId
              ),
              vcu: state.alerts.alertData.vcu.filter(
                (item: any) => item.alertId != actionParams.payload.alertId
              ),
              charger: state.alerts.alertData.charger.filter(
                (item: any) => item.alertId != actionParams.payload.alertId
              ),
            },
          },
          alertClearedSuccess: true,
        };
      } else {
        return {
          ...state,
          alertClearedSuccess: false,
        };
      }
    }

    case "SET_ALERT_TOGGLE": {
      return {
        ...state,
        alertClearedSuccess: false,
      };
    }
    case "RESET_MAIN_ALERTS_LOADING": {
      return {
        ...state,
        mainAlertsLoading: true,
        alerts: {
          ...state.alerts,
          alertData: {
            smart: [],
            bms: [],
            mc: [],
            vcu: [],
            charger: [],
          },
        },
      };
    }
    case "RESET_SINGLE_ALERTS_LOADING": {
      return {
        ...state,
        additional_insights_loading: actionParams.payload,
        past_alerts_loading: actionParams.payload,
        graphs_loading: actionParams.payload,
      };
    }
    case "STORE_FILTERED_ALERT_COUNT_UPDATE": {
      return {
        ...state,
        alertsCount: {
          ...state.alertsCount,
          smartCount: actionParams.payload.smartCount.totalNoOfElements,
          bmsCount: actionParams.payload.bmsCount.totalNoOfElements,
          mcCount: actionParams.payload.mcCount.totalNoOfElements,
          vcuCount: actionParams.payload.vcuCount.totalNoOfElements,
          chargerCount: actionParams.payload.chargerCount.totalNoOfElements,
        },
      };
    }
    case "STORE_GET_SERVICES":
      return {
        ...state,
        getServicesValues: actionParams.payload,
      };
    case "UPDATE_MODULE_VIEW":
      return {
        ...state,
        view_module_type: actionParams.payload,
      };
    case "INSERT_CHECKED_ROW_ITEM":
      return {
        ...state,
        checked_table_items: actionParams.payload,
      };
    case "UPDATE_PANEL_VIEW_MODE":
      return {
        ...state,
        panel_view_mode: actionParams.payload,
      };
    case "GRID_FROM_DYNAMO":
      console.log("is_grid_from_dynamodbPARAMS", actionParams.payload);
      return {
        ...state,
        is_grid_from_dynamodb: actionParams.payload,
      };
    case "UPDATE_TRIP_LIST":
      return {
        ...state,
        trip_list: actionParams.payload,
      };
    case "TRIP_LOADING_STATUS":
      return {
        ...state,
        isTripLoading: actionParams.payload,
      };
    case "B2B_QUICKLINK_VISIBLE":
      return {
        ...state,
        b2b_quicklink_visible: actionParams.payload,
      };
    case "UPDATE_LIVE_VEHICLE_OBJECT":
      return {
        ...state,
        b2b_live_vehicle_object: actionParams.payload,
      };
    case "TRIP_DATA_LOADED_STATUS":
      return {
        ...state,
        b2b_trip_data_table_loaded: actionParams.payload,
      };
    case "GRID_DATA_LOADED_STATUS":
      return {
        ...state,
        b2b_grid_data_table_loaded: actionParams.payload,
      };
    case "INSERT_B2B_TRIP_DATA":
      return {
        ...state,
        b2b_trip_table_data: actionParams.payload,
      };
    case "INSERT_B2B_GRID_DATA":
      return {
        ...state,
        b2b_grid_table_data: actionParams.payload,
      };
    case "UPDATE_USER_PROFILE":
      return {
        ...state,
        user_profile: actionParams.payload,
      };
    case "STORE_VEHICLE_INSIGHTS_CHARTS":
      return {
        ...state,
        b2b_vehicle_insights_data: actionParams.payload,
        b2b_vehicle_insights_loading: false,
      };
    case "STORE_TRIP_INSIGHTS_CHARTS":
      return {
        ...state,
        b2b_trip_insights_data: actionParams.payload,
      };
    case "STORE_BATTERY_INSIGHTS_CHARTS":
      return {
        ...state,
        b2b_battery_insights_data: actionParams.payload,
        b2b_battery_insights_loading: false,
      };
    case "LOADING_VEHICLE_INSIGHTS_CHARTS":
      return {
        ...state,
        b2b_vehicle_insights_loading: actionParams.payload,
      };
    case "LOADING_BATTERY_INSIGHTS_CHARTS":
      return {
        ...state,
        b2b_battery_insights_loading: actionParams.payload,
      };
    case "STORE_VEHICLE_INSIGHTS_CHARTS_ERROR": {
      return {
        ...state,
        b2b_vehicle_insights_loading_error: actionParams.payload,
      };
    }
    case "STORE_BATTERY_INSIGHTS_CHARTS_ERROR": {
      return {
        ...state,
        b2b_battery_insights_loading_error: actionParams.payload,
      };
    }
    case "SHOW_ADD_POLICY_DRAWER":
      return {
        ...state,
        show_add_policy_drawer: actionParams.payload,
      };
    case "MODULE_IS_LOADING":
      return {
        ...state,
        module_is_loading: actionParams.payload,
      };
    case "POLICY_SEARCH_KEYWORD":
      return {
        ...state,
        policy_search_keyword: actionParams.payload,
      };
    case "EMPTY_POLICY_GRID":
      return {
        ...state,
        componentData: actionParams.payload,
      };
    case "TOGGLE_POLICY_ADD_GRID_MODAL":
      return {
        ...state,
        policy_add_grid_modal: actionParams.payload,
      };
    case "TOGGLE_POLICY_ADD_GEOFENCE_MODAL":
      return {
        ...state,
        policy_add_geofence_modal: actionParams.payload,
      };
    case "CURRENT_POLICY_TAB":
      return {
        ...state,
        current_policy_tab: actionParams.payload,
      };
    case "CURRENT_POLICY_NAME":
      return {
        ...state,
        current_policy_name: actionParams.payload,
      };
    case "TOGGLE_POLICY_EDIT_GRID_MODAL":
      return {
        ...state,
        policy_edit_grid_modal: actionParams.payload,
      };
    case "RESET_ALL_POLICY_MODALS":
      return {
        ...state,
        policy_edit_grid_modal: false,
        policy_add_grid_modal: false,
        policy_add_geofence_modal: false,
      };
    // case "STORE_CS_SUBCOMPONENTS": {
    //   return {
    //     ...state,
    //     csSubmoduleComponents: actionParams.payload,
    //   };
    // }

    // case "STORE_CS_GRID_DATA": {
    //   return {
    //     ...state,
    //     csGridDataSource: actionParams.payload,
    //   };
    // }
    case "INSERT_TOGGLE_STATUS": {
      return { ...state, bulk_upload_history: actionParams.payload };
    }
    // case "STORE_CS_SUBCOMPONENTS": {
    //   return {
    //     ...state,
    //     csSubmoduleComponents: actionParams.payload,
    //   };
    // }

    // case "STORE_CS_GRID_DATA": {
    //   return {
    //     ...state,
    //     csGridDataSource: actionParams.payload,
    //   };
    // }

    // case "STORE_CS_GRID_COLUMN": {
    //   return {
    //     ...state,
    //     csGridDataColumn: actionParams.payload,
    //   };
    // }

    // case "STORE_CS_LOCATION_DROPDOWN": {
    //   return {
    //     ...state,
    //     csLocationDropDowns: actionParams.payload,
    //   };
    // }

    // case "CS_PANEL_OPEN": {
    //   return {
    //     ...state,
    //     csPanelIsOpened: actionParams.payload,
    //   };
    // }
    // case "SET_PANEL_MODE":
    //   return {
    //     ...state,
    //     csPanelMode: actionParams.payload,
    //   };
    // case "SET_ROW_CLICK_VALUE":
    //   return {
    //     ...state,
    //     csRowClickValue: actionParams.payload,
    //   };
    // case "STORE_INSERT_NEW_STATION":
    //   return {
    //     ...state,
    //     stationAddedSuccess: actionParams.payload,
    //   };
    // case "INSERT_NEW_STATION_STATUS":
    //   return {
    //     ...state,
    //     stationAddedSuccess: actionParams.payload,
    //   };
    // case "STORE_UPDATE_CHARGING_STATION":
    //   return {
    //     ...state,
    //     stationAddedSuccess: actionParams.payload,
    //   };
    // case "STORE_FILTER_GRID_DATA":
    //   return {
    //     ...state,
    //     csGridDataSource: actionParams.payload,
    //   };
    // case "STATION_SEARCH_INPUT":
    //   return {
    //     ...state,
    //     csSearchInput: actionParams.payload,
    //   };
    // case "CS_MODULE_CHANGE":
    //   return {
    //     ...state,
    //     csModule: actionParams.payload,
    //   };
    // case "STORE_GET_STATION_ADD_FORM": {
    //   return {
    //     ...state,
    //     csAddStationForm: actionParams.payload,
    //   };
    // }
    // case "STORE_GET_CS_MODULE":
    //   return {
    //     ...state,
    //     csModules: actionParams.payload,
    //   };
    // case "STORE_GET_CS_MODULE_CHANGE":
    //   return {
    //     ...state,
    //     csSubModules: actionParams.payload,
    //   };
    // case "STORE_GET_CS_GRID_DATA_SOURCE":
    //   return {
    //     ...state,
    //     csGridDataSource: actionParams.payload,
    //   };
    // case "CLEAR_STATION_DATA":
    //   return {
    //     ...state,
    //     csGridDataSource: {},
    //     csSubModules: {},
    //     csModules: {},
    //     csSearchInput: "",
    //   };
    // case "UPDATE_CURRENT_MODULE":
    //   return {
    //     ...state,
    //     csModule: actionParams.payload,
    //   };
    // case "CHANGE_CS_VIEW_MODE": {
    //   return {
    //     ...state,
    //     csViewMode: actionParams.payload,
    //   };
    // }
    // case "STORE_CS_WEB_COMPONENTS":
    //   return {
    //     ...state,
    //     csWebComponents: actionParams.payload,
    //   };
    case "QUICK_SIGHT_LOADING": {
      return {
        ...state,
        quicksightloading: actionParams.payload,
      };
    }
    case "UPDATE_DYNAMIC_SEARCH": {
      return {
        ...state,
        dynamicComponentSearchValue: actionParams.payload,
      };
    }

    //Device Control Management
    case "STORE_ALL_FIRMWARE": {
      return {
        ...state,
        getAllFirmware: actionParams.payload,
      };
    }
    case "STORE_ORG_DROPDOWN": {
      return {
        ...state,
        getOrgDropDown: actionParams.payload,
      };
    }

    case "STORE_ALL_ASSIGNED_FIRMWARE": {
      return {
        ...state,
        getAllAssignedFirmware: actionParams.payload,
      };
    }
    case "STORE_ALL_IOT_DEVICE": {
      return {
        ...state,
        getAllIotDevice: actionParams.payload,
      };
    }
    case "STORE_OTA_HOME_PAGE_DATA": {
      return {
        ...state,
        otaHomePageData: actionParams.payload,
      };
    }
    case "STORE_SEARCH_IOT_DEVICE": {
      return {
        ...state,
        searchIotDevice: actionParams.payload,
      };
    }
    case "STORE_FIRMWARE_LIST_DROPDOWN": {
      return {
        ...state,
        firmWareList: actionParams.payload,
      };
    }
    case "STORE_ALL_JOBS": {
      return {
        ...state,
        allJobsList: actionParams.payload,
        jobIsLoading: false,
      };
    }
    case "IS_THING_LOADING": {
      return {
        ...state,
        isThingDataLoading: actionParams.payload,
      };
    }
    case "STORE_GET_JOB_DETAILS_BY_JOB_ID": {
      return {
        ...state,
        getJobDetailsByJobId: actionParams.payload,
        jobdetailsloading: false,
      };
    }
    case "STORE_GET_ALL_THING_GROUPS": {
      return {
        ...state,
        getAllThingGroup: actionParams.payload,
      };
    }

    case "STORE_GET_THING_GROUP_DETAIL_BY_NAME": {
      return {
        ...state,
        getThingGroupDetailByName: actionParams.payload,
        DeviceGroupDetailIsLoading: false,
      };
    }
    case "STORE_SEARCH_ALL_THING_GROUP": {
      return {
        ...state,
        searchAllGroupDevice: actionParams.payload,
      };
    }

    case "STORE_ALL_DEVICES": {
      return {
        ...state,
        getAllDevice: actionParams.payload,
      };
    }

    case "STORE_ALL_SELECTED_VCU": {
      if (actionParams.payload) {
        return {
          ...state,
          allSelectedVCU: {
            ...state.allSelectedVCU,
            selectedVCUlist: {
              ...state.allSelectedVCU.selectedVCUlist,
              VCUDetails: actionParams.payload,
            },
          },
        };
      } else {
        // Row is deselected, remove it from the array
        return {
          ...state,
          allSelectedVCU: {
            ...state.allSelectedVCU,
            selectedVCUlist: {
              ...state.allSelectedVCU.selectedVCUlist,
              VCUDetails:
                state.allSelectedVCU.selectedVCUlist.VCUDetails.filter(
                  (id: any) => id !== actionParams.payload
                ),
            },
          },
        };
      }
    }
    case "STORE_ALL_DEVICE_GROUPS": {
      return {
        ...state,
        allDeviceGroupsList: actionParams.payload,
        DeviceGroupIsLoading: false,
      };
    }

    case "STORE_GET_DEVICE_GROUP_DETAILS_BY_GROUP_ID": {
      return {
        ...state,
        getDeviceGroupDetailsByDeviceGroupId: actionParams.payload,

        DeviceGroupdetailsloading: false,
      };
    }

    case "STORE_ALL_FILTER_DEVICES": {
      return {
        ...state,
        allFilterDeviceList: actionParams.payload,
        FilterDeviceIsLoading: false,
      };
    }

    case "STORE_ALL_FILTER_DEVICE_VALUES": {
      return {
        ...state,
        allFilterDeviceDropdownList: actionParams.payload,
        FilterDeviceDropDownIsLoading: false,
      };
    }

    case "STORE_ALL_DEVICES_ADDED_TO_GROUP": {
      return {
        ...state,
        addDevicesToGroup: actionParams.payload,
      };
    }

    case "STORE_GET_ALL_DEVICES_FROM_DEVICE_GROUP": {
      return {
        ...state,
        getDevicesFromGroup: actionParams.payload,
        DeviceGroupDetailIsLoading: false,
      };
    }

    //swapping station
    case "STORE_OVERVIEW_COUNT_STATIONS": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          console: {
            ...state.swapping_station.console,
            overview: {
              ...state.swapping_station.console.overview,
              total_stations: actionParams.payload,
            },
          },
        },
      };
    }
    case "STORE_OVERVIEW_COUNT_BATTERIES": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          console: {
            ...state.swapping_station.console,
            overview: {
              ...state.swapping_station.console.overview,
              total_batteries: actionParams.payload,
            },
          },
        },
      };
    }
    case "STORE_OVERVIEW_COUNT_SWAPS": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          console: {
            ...state.swapping_station.console,
            overview: {
              ...state.swapping_station.console.overview,
              total_swaps: actionParams.payload,
            },
          },
        },
      };
    }
    case "STORE_SWAPPING_STATION_LISTS": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          console: {
            ...state.swapping_station.console,
            stations_list: actionParams.payload,
          },
        },
      };
    }
    case "STORE_USAGE_OVERVIEW": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          console: {
            ...state.swapping_station.console,
            overview: {
              ...state.swapping_station.console.overview,
              usage_overview: actionParams.payload,
            },
          },
        },
      };
    }
    case "STORE_SLOT_DISTRIBUTION": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          console: {
            ...state.swapping_station.console,
            overview: {
              ...state.swapping_station.console.overview,
              slot_distribution: actionParams.payload,
            },
          },
        },
      };
    }
    case "SWAPPING_STATION_SHOW_MAP_VIEW": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          console: {
            ...state.swapping_station.console,
            map_visible: actionParams.payload,
          },
        },
      };
    }
    case "STORE_STATION_DETAILS_BY_ID": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          station_details: {
            ...state.swapping_station.station_details,
            station_data: actionParams.payload,
          },
        },
      };
    }
    case "STORE_BATTERY_LIST_BY_STATION_ID": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          station_details: {
            ...state.swapping_station.station_details,
            battery_list: actionParams.payload,
          },
        },
      };
    }
    case "STORE_BATTERY_LIST_BY_STATION_ID": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          station_details: {
            ...state.swapping_station.station_details,
            battery_list: actionParams.payload,
          },
        },
      };
    }
    case "STORE_SWAP_HISTORY_BY_STATION_ID": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          station_details: {
            ...state.swapping_station.station_details,
            swap_history: actionParams.payload,
          },
        },
      };
    }
    case "STORE_BOOKING_DETAILS_BY_STATION_ID": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          station_details: {
            ...state.swapping_station.station_details,
            booking_details: actionParams.payload,
          },
        },
      };
    }
    // case "SWAPPING_STATION_SEARCH_KEYWORD": {
    //   return {
    //     ...state,
    //     swapping_station: {
    //       ...state.swapping_station,
    //       console: {
    //         ...state.swapping_station.console,
    //         search_keyword: actionParams.payload
    //       }
    //     }
    //   }
    // }
    case "STORE_SWAP_USAGE": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          console: {
            ...state.swapping_station.console,
            overview: {
              ...state.swapping_station.console.overview,
              //hello
              swap_usage: actionParams.payload,
            },
          },
        },
      };
    }
    case "STORE_GET_RIDERS_LIST": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          rider_details: {
            ...state.swapping_station.rider_details,
            rider_list: actionParams.payload,
          },
        },
      };
    }
    case "STORE_SWAP_HISTORY_BY_USER_ID": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          rider_details: {
            ...state.swapping_station.rider_details,
            rider_swap_history: actionParams.payload,
            rider_swap_history_loading: false,
          },
        },
      };
    }
    case "USER_RIDE_HISTORY_LOADING": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          rider_details: {
            ...state.swapping_station.rider_details,
            rider_swap_history_loading: actionParams.payload,
          },
        },
      };
    }
    case "STORE_OVERVIEW_COUNT_CONSUMERS": {
      return {
        ...state,
        swapping_station: {
          ...state.swapping_station,
          console: {
            ...state.swapping_station.console,
            overview: {
              ...state.swapping_station.console.overview,
              total_consumers: actionParams.payload,
            },
          },
        },
      };
    }
    // charging station reducers
    case "CHARGING_STATION_SHOW_MAP_VIEW": {
      return {
        ...state,
        charging_station: {
          ...state.charging_station,
          console: {
            ...state.charging_station.console,
            map: actionParams.payload,
          },
        },
      };
    }
    case "STORE_CHARGING_STATION_DETAILS": {
      return {
        ...state,
        charging_station: {
          ...state.charging_station,
          console: {
            ...state.charging_station.console,
            charging_stations_list: actionParams.payload,
          },
        },
      };
    }
    case "STORE_CHARGING_DETAILS_BY_ID": {
      return {
        ...state,
        charging_station: {
          ...state.charging_station,
          console: {
            ...state.charging_station.console,
            charging_station_data: actionParams.payload,
          },
        },
      };
    }
    case "CHARGING_STATION_SEARCH_KEYWORD": {
      return {
        ...state,
        charging_station: {
          ...state.charging_station,
          console: {
            ...state.charging_station.console,
            search_keyword: actionParams.payload,
          },
        },
      };
    }
    case "STORE_CHARGING_PROFILE_DETAILS": {
      return {
        ...state,
        charging_station: {
          ...state.charging_station,
          console: {
            ...state.charging_station.console,
            charging_profile_list: actionParams.payload,
          },
        },
      };
    }

    case "STORE_RESERVATION_DATA": {
      return {
        ...state,
        charging_station: {
          ...state.charging_station,
          console: {
            ...state.charging_station.console,
            reservation_data: actionParams.payload,
          },
        },
      };
    }
    case "STORE_TRANSACTION_DATA": {
      return {
        ...state,
        charging_station: {
          ...state.charging_station,
          console: {
            ...state.charging_station.console,
            transaction_data: actionParams.payload,
          },
        },
      };
    }
    case "STORE_OCPP_TAG_DETAILS": {
      return {
        ...state,
        charging_station: {
          ...state.charging_station,
          console: {
            ...state.charging_station.console,
            ocpp_tag_data: actionParams.payload,
          },
        },
      };
    }
    case "STORE_OCPP_TAG_DETAILS_BY_ID": {
      return {
        ...state,
        charging_station: {
          ...state.charging_station,
          console: {
            ...state.charging_station.console,
            ocpp_tag_details: {
              ...state.charging_station.console.ocpp_tag_details,
              ocpp_tag_list: actionParams.payload,
            },
          },
        },
      };
    }
    case "STORE_CHARGE_POINT_DETAILS": {
      return {
        ...state,
        charging_station: {
          ...state.charging_station,
          console: {
            ...state.charging_station.console,
            charge_point_data: actionParams.payload,
          },
        },
      };
    }
    case "STORE_CHARGING_PROFILE_DETAILS_BY_ID": {
      return {
        ...state,
        charging_station: {
          ...state.charging_station,
          console: {
            ...state.charging_station.console,
            charging_profile_data: {
              ...state.charging_station.console.charging_profile_data,
              profile_data: actionParams.payload,
            },
          },
        },
      };
    }
    case "STORE_CONNECTOR_DETAILS": {
      return {
        ...state,
        charging_station: {
          ...state.charging_station,
          console: {
            ...state.charging_station.console,
            charging_profile_data: {
              ...state.charging_station.console.charging_profile_data,
              connector_data: actionParams.payload,
            },
          },
        },
      };
    }
    case "STORE_SCHEDULE_PERIOD_DETAILS_BY_PROFILE_ID": {
      return {
        ...state,
        charging_station: {
          ...state.charging_station,
          console: {
            ...state.charging_station.console,
            charging_profile_data: {
              ...state.charging_station.console.charging_profile_data,
              Schedule_period_data: actionParams.payload,
            },
          },
        },
      };
    }

    //refund model reducers
    // case "STORE_PAYMENT_DETAILS": {
    //   return {
    //     ...state,
    //     paymentdetailsloading: actionParams.payload,
    //   };
    // }

    // case "REFUND_MODEL_SEARCH_KEYWORD": {
    //   return {
    //     ...state,
    //     search_keyword: actionParams.payload,
    //   };
    // }
    // case "STORE_REFUND_MODEL_SEARCH_KEYWORD": {
    //   return {
    //     ...state,
    //     payment_details_list: actionParams.payload,
    //   };
    // }

    // case "STORE_REFUND_MODEL_RIDE_ID_SEARCH_KEYWORD": {
    //   return {
    //     ...state,
    //     ride_list: actionParams.payload,
    //   };
    // }
    case "CREATE_GEOFENCE_POLICY": {
      return {
        ...state,
        policy_creation_geofence: actionParams.payload,
      };
    }
    case "STORE_PRICING_GRIDdATA": {
      return { ...state, store_pricing_data: actionParams.payload };
    }
    default: {
      return state;
    }
  }
};

export default AppReducer;
